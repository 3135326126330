import React, { Component, Fragment } from "react";
import { postman } from "../../postman";
import { filter } from "rxjs/operators";
import Cookies from "js-cookie";
import { createBaseEvent } from "../../events/event-creator";
import { Transition } from "@headlessui/react";
import { eventActionMap, fireEvent } from "../../events/events.helper";
import { IconElement } from "../elements/icon.element";

export class CookieConsent extends Component {
  #letterbox;

  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      show: false,
    };

    this.watchDog = this.watchDog.bind(this);
  }

  componentDidMount() {
    if (!this.#letterbox) {
      this.#letterbox = postman
        .pipe(filter(evt => evt?.key === "cookie-consent"))
        .subscribe(this.watchDog);
    }

    this.initialize();
  }

  componentWillUnmount() {
    this.#letterbox.unsubscribe();
  }

  initialize() {
    const status = Cookies.get("cookieconsent_status") || "pending";

    fireEvent(
      createBaseEvent("cookie-consent", "consent", {
        allowedAll: status === "allow",
        deniedAll: status === "deny",
        pendingAll: status === "pending",
      })
    );

    this.setState({
      initialized: true,
      show: status === "pending",
    });
  }

  watchDog(evt) {
    return eventActionMap(evt, {
      open: () => {
        this.setState({
          show: true,
        });
      },
    });
  }

  allow() {
    Cookies.set("cookieconsent_status", "allow");
    this.initialize();
  }

  deny() {
    Cookies.set("cookieconsent_status", "deny");
    this.initialize();
  }

  reset() {
    Cookies.set("cookieconsent_status", "pending");
    this.initialize();
  }

  render() {
    const { initialized, show } = this.state;

    return (
      <Fragment>
        {initialized && (
          <div className="fixed z-50 bottom-0 right-0 lg:right-4 flex items-end justify-center pt-4 px-0 md:px-4 text-center sm:block sm:p-0">
            <Transition
              className="inline-block align-bottom bg-white md:rounded-lg px-4 pt-5 pb-4 text-left shadow-2xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              show={show}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <span className="text-green-600 text-2xl">
                    <IconElement icon="cookieBite" />
                  </span>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <strong
                    className="block text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    Privatsphäre ist wichtig
                  </strong>
                  <div className="mt-2">
                    <p className="text-sm leading-5 text-gray-700">
                      Diese Website verwendet Cookies um den Datenverkehr zu
                      analysieren und um Werbeanzeigen zu messen.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <span className="flexw-full rounded-md shadow-sm">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => this.allow()}
                    data-tracking-action-label="consent"
                    data-tracking-action-value="allow"
                    data-tracking-action-position="cookie-consent-full-screen-modal"
                  >
                    Cookies zulassen
                  </button>
                </span>

                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent mx-4 mt-2 bg-transparent text-xs leading-6 font-medium text-gray-700"
                  onClick={() => this.deny()}
                  data-tracking-action-label="consent"
                  data-tracking-action-value="deny"
                  data-tracking-action-position="cookie-consent-full-screen-modal"
                >
                  Ich möchte nur die notwendigsten Cookies zulassen
                </button>
              </div>
            </Transition>
          </div>
        )}
      </Fragment>
    );
  }
}
